<template>
  <v-container mx-auto py-0>
    <v-toolbar
      app
      fixed
      color="white"
      class="elevation-3"
      height="64"
    >
      <v-layout justify-center>
        <v-img
          contain
          height="48"
          width="48"
          max-width="48"
          :src="require('@/assets/logo.png')"
          :to="{ name: 'Home' }"
        />
      </v-layout>
    </v-toolbar>
    <v-layout row wrap justify-center align-center>
      <v-flex text-center style="margin-top:60px;">
        <p class="display-4 font-weight-bold error--text">Oops!</p>
        <p class="display-1">You have no access rights to this page</p>
        <span>Please <a class="font-weight-medium" @click="goBack">return</a> to the previous page, or visit the <a class="font-weight-medium" @click="goHome">homepage</a></span>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  methods: {
    goBack () {
      window.history.back()
    },
    goHome () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
